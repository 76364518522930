(function() {
  async function loadDegreeData() {
    associateDegreeInfo = {};
    try {
      const associateDegreeInfoResponse = await fetch("./data/AssociateDegreeInfo.json")
      associateDegreeInfo = await associateDegreeInfoResponse.json();
    } catch (err) {
      console.log("Unable to load in degree data");
      console.log(err);
      associateDegreeInfo = {};
    }

    _APP.storeAllDegrees(associateDegreeInfo);
  }

  async function loadCoursesData() {
    let courseInfo = {};
    try {
      const courseInfoResponse = await fetch("./data/ClassInfo.json");
      courseInfo = await courseInfoResponse.json();
    } catch (err) {
      console.log("Unable to load in course data");
      console.log(err);
      courseInfo = {};
    }

    _APP.storeAllCourses(courseInfo);
  }

  window._APP = { 
    ...window._APP, 
    loadCoursesData,
    loadDegreeData,
  };
})();